import {
  DynamicImage,
  PrimaryButton,
  ProgressBar,
  Seo,
  Text,
} from 'components';
import Header from 'components/Header';

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import 'swiper/swiper.min.css';
import LoadingBar from './components/LoadingBar';
import { useRouter } from 'apis/history';
import { Quiz } from 'types/quiz';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import QuestionMark from 'assets/icons/question-mark.svg';
import PopupX from 'assets/icons/popup-x.svg';
import { normalizeStates } from 'utils/localization';
import quizSvgs from 'utils/constants/quizSvgs';
import MgStar from 'assets/icons/mg/star.svg';

SwiperCore.use([Autoplay]);

interface SectionObj {
  text: string;
  bgColor: string;
  barColor: string;
  progress: number;
}

interface DataSection {
  text: string;
  bgColor: string;
  bgColorActive: string;
}

const Finalizing = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const data = useQuizData('finalizing');
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const { countdownDiffInMS } = useFirstVisitDate();
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { goToEmail, goToResults } = useRouter();
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user
  );

  const { isMobile } = useQuery();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || ''
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || ''
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  const [sections, setSections] = useState<SectionObj[]>([]);

  const loadSection = (sectionIndex: number): Promise<void> => new Promise(resolve => {
      let prog = 0;
      const slowerLowerLimit = Math.floor(Math.random() * 41) + 30;

      const id = setInterval(() => {
        if (prog >= slowerLowerLimit && prog <= slowerLowerLimit + 20) {
          prog += 0.3;
        } else {
          prog += 1;
        }

        setSections(prevSections => {
          const newSections = [...prevSections];
          newSections[sectionIndex] = {
            ...newSections[sectionIndex],
            progress: prog,
          };
          return newSections;
        });

        if (prog >= 100) {
          clearInterval(id);

          setTimeout(() => {
            resolve();
          }, 2000);
        }
      }, 60);
    });

  const loadLoaders = useCallback(async () => {
    for (let i = 0; i < sections.length; i++) {
      await loadSection(i);
      if (i === sections.length - 1) {
        setTimeout(() => {
          goToEmail();
        }, 2000);
      }
    }
  }, [sections.length]);

  useEffect(() => {
    if (data) {
      const newSections: SectionObj[] = [];

      data.sections.forEach((section: DataSection) => {
        const newSec: SectionObj = {
          text: section.text,
          bgColor: section.bgColor,
          barColor: section.bgColorActive,
          progress: 0,
        };
        newSections.push(newSec);
      });

      setSections(newSections);
      loadLoaders();
    }
  }, [data, loadLoaders]);

  const renderSvgImage = (svgKey: keyof typeof quizSvgs) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (!isPaused) {
  //       switch (currentStep) {
  //         case 0:
  //           setProgress1((prevProgress) => {
  //             const newProgress = prevProgress + 1;
  //             if (newProgress >= 100) {
  //               clearInterval(intervalId);
  //               setTimeout(() => {
  //                 setCurrentStep(1);
  //               }, 400);
  //             }

  //             return newProgress;
  //           });
  //           break;
  //         case 1:
  //           setProgress2((prevProgress) => {
  //             const newProgress =
  //               prevProgress +
  //               (prevProgress >= 64 && prevProgress <= 74 ? 0.3 : 1);

  //             if (newProgress >= 100) {
  //               clearInterval(intervalId);
  //               setTimeout(() => {
  //                 setCurrentStep(2);
  //               }, 400);
  //             }

  //             // if (prevProgress < 75 && newProgress >= 75) {
  //             //   setShowPopup(true);
  //             //   setIsPaused(true);
  //             // }

  //             return newProgress;
  //           });
  //           break;
  //         case 2:
  //           setProgress3((prevProgress) => {
  //             const newProgress =
  //               prevProgress +
  //               (prevProgress >= 29 && prevProgress <= 39 ? 0.3 : 1);

  //             if (newProgress >= 100) {
  //               clearInterval(intervalId);
  //               setCurrentStep(3);
  //               handleNavigationOnCounterEnd();
  //             }

  //             // if (prevProgress < 40 && newProgress >= 40) {
  //             //   setShowPopup(true);
  //             //   setIsPaused(true);
  //             // }

  //             return newProgress;
  //           });
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }, 60);

  //   return () => clearInterval(intervalId);
  // }, [currentStep, isPaused]);

  const handleNavigationOnCounterEnd = () => {
    // setTimeout(() => {
    //   if (variant === 'funnel-email' || variant === 'email-first') {
    //     return goToEmail();
    //   }
    //   goToResults();
    // }, 1500);
  };

  if (!data) {
    return null;
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsPaused(false);
  };

  const Star = () => {
    const Svg = quizSvgs['MgStar'];

    return (
      <div className="star-container">
        <Svg />
      </div>
    );
  };

  return (
    <MainContainer>
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}

      <Header
        logoVariant="center"
        sticky={false}
        color="white"
        hasBackground={true}
        hasDivider={false}
      />
      <Grid>
        <Container>
          {data.title && <Title>{data.title}</Title>}
          <SectionsContainer>
            {sections.map((section, i) => (
              <Section key={`section-${i}`}>
                <SectionText>
                  <p>{section.text}</p>
                  <p>{Math.round(section.progress)}%</p>
                </SectionText>
                <LoaderContainer>
                  <Loader bgColor={section.bgColor} progress={section.progress}>
                    <StarContainer progress={section.progress}>
                      <MgStar />
                    </StarContainer>
                  </Loader>
                </LoaderContainer>
              </Section>
            ))}
          </SectionsContainer>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            style={{ width: '100%' }}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
          >
            {data.reviews.map(
              (
                review: { title: string; name: string; content: string },
                index: any
              ) => (
                <SwiperSlide key={`review-${index}`}>
                  <Review>
                    {renderSvgImage('reviewStars')}
                    <TitleContainer>
                      <ReviewTitle>{review.title}</ReviewTitle>
                      <ReviewName>{review.name}</ReviewName>
                    </TitleContainer>
                    <ReviewText>{review.content}</ReviewText>
                  </Review>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Container>
      </Grid>
    </MainContainer>
  );
};

export default Finalizing;

const Title = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  max-width: 28.75rem;
  margin-bottom: 2.25rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  min-height: calc(100vh - 68px);
  max-width: 28.75rem;
  width: 100%;

  @media ${tablet} {
    padding: 1.5rem 1rem;
    max-width: 23.4375rem;
  }
`;

const MainContainer = styled.div`
  background: radial-gradient(
    110.2% 112.25% at 77.91% 22.09%,
    #414d7b 0%,
    #0a0c11 100%
  );
  color: ${({ theme }) => theme.colors.white};
`;

const SectionsContainer = styled.div`
  display: grid;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 2rem;

  @media ${tablet} {
    margin-bottom: 3.5rem;
  }
`;

const Section = styled.div`
  display: grid;
  gap: 0.25rem;
`;

const SectionText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.white};
`;

const LoaderContainer = styled.div`
  padding: 0.5rem 0;
`;

const Loader = styled.div<{ bgColor: string; progress: number }>`
  height: 0.5rem;
  width: 100%;
  border-radius: 6.25rem;
  background-color: ${({ bgColor }) => bgColor};
  position: relative;

  ::after {
    content: "";
    position: absolute;
    height: 100%;
    border-radius: 6.25rem;
    width: ${({ progress }) => progress}%;
    background-color: ${({ theme }) => theme.colors.mgSecondary100};
    box-shadow: 0px 0px 6px 0px transparent;
    transition: width 120ms linear, background-color 500ms ease-out,
      box-shadow 500ms ease-out;
    ${({ progress }) =>
      progress >= 100 &&
      'animation: changeBackground 500ms linear 500ms forwards'};
  }

  @keyframes changeBackground {
    to {
      background-color: white;
      box-shadow: 0px 0px 6px 0px #fff;
    }
  }
`;

const StarContainer = styled.div<{ progress: number }>`
  position: absolute;
  z-index: 10;
  left: ${({ progress }) => progress - 1}%;
  top: 50%;
  transform-origin: center;
  transform: translateY(-50%) translateX(-50%) scale(1);
  transition: transform 1000ms ease-out, left 60ms linear,
    opacity 100ms ease-out;
  height: 2.5rem;
  width: 2.5rem;
  opacity: ${({ progress }) => (progress > 0 ? '1' : '0')};
  ${({ progress }) =>
    progress >= 100 &&
    'animation: scaleRotateAndDisappear 1500ms linear forwards'};

  svg {
    height: 100%;
    width: 100%;
  }

  @keyframes scaleRotateAndDisappear {
    0% {
      transform: translateY(-50%) translateX(-50%) scale(1);
    }

    10% {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) scale(1.5);
    }

    66% {
      opacity: 1;
      transform: translateY(-50%) translateX(-50%) scale(1) rotate(-11deg);
    }

    100% {
      opacity: 0;
      transform: translateY(-50%) translateX(-50%) scale(0.5) rotate(-22deg)
        skew(30deg, 20deg);
    }
  }
`;

const Review = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  /* margin-top: 2rem; */
  width: 100%;
  min-height: 8.15948rem;

  @media ${tablet} {
    padding: 1rem;
    gap: 0.5rem;
    /* margin-top: 1.5rem; */
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
`;

const ReviewTitle = styled(Text)`
  color: #000;
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const ReviewName = styled(Text)`
  color: #6e6e6e;
  font-family: "Afacad", sans-serif;
  text-align: right;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const ReviewText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  overflow-x: hidden;
`;
